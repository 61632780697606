[data-v-123568c8] .avue-crud .el-table {
  height: calc(100vh - 344px) !important;
  max-height: calc(100vh - 344px) !important;
}
.summaryBox[data-v-123568c8] {
  width: 100%;
  background-color: rgb(233, 237, 243);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}
.summaryTitle[data-v-123568c8] {
  width: 50%;
  height: 70px;
  line-height: 70px;
  text-align: center;
}
.summaryLittle[data-v-123568c8] {
  padding: 0 10px;
  text-align: center;
  height: 70px;
  line-height: 70px;
  border-left: 1px solid #333333;
}
.summaryLittle[data-v-123568c8]:last-child {
  border-right: 1px solid #333333;
}
.iframeBox[data-v-123568c8] {
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 12px;
  z-index: 99;
}
.formContentBox[data-v-123568c8] {
  padding: 12px;
  height: calc(100% - 94px);
}
.iframeBox1[data-v-123568c8] {
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 12px;
  z-index: 99;
}
.iframe[data-v-123568c8] {
  width: 100%;
  height: calc(100% - 28px);
  border: none;
}